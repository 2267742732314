<template>
    <div>
        <Banner />
        <Services />
        <Prices />
        <About />
        <Gallery />
    </div>
</template>

<script>
import Banner from "./Banner.vue";
import Services from "./Services.vue";
import Prices from "./Prices.vue";
import About from "./About.vue";
import Gallery from "./Gallery.vue";

export default {
    components: {
        Banner,
        Services,
        Prices,
        About,
        Gallery,
    },
};
</script>
