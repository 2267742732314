<template>
    <div id="about" class="section">
        <div class="wrapper">
            <h2 class="section-title">За нас</h2>
            <p>
                Настъпи дългоочакваният момент да се прибера в родината си и да
                практикувам дългогодишният опит натрупан в чужбина. Нашият екип
                от високо квалифицирани професионалисти е готов да се погрижи за
                своите клиенти с необходимото оборудване, високо качество на
                услугите и отношенията.
            </p>
            <iframe
                title="Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.7931551537226!2d23.417323699999997!3d42.6657385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa864ed7cde7ad%3A0x16625efdc0ddd51a!2z0YPQuy4g4oCeNTAxMOKAnCDihJYgMSwgMTUyOCDQndCf0Jcg0JjRgdC60YrRgCwg0KHQvtGE0LjRjw!5e0!3m2!1sbg!2sbg!4v1655145268633!5m2!1sbg!2sbg"
                width="100%"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    </div>
</template>
