<template>
    <div id="services" class="section">
        <div class="wrapper">
            <h2 class="section-title">Услуги</h2>
            <p>24/7 Денонощна Пътна Помощ Фирма Ел Зет 66 Ауто предлага:</p>
            <ul>
                <li>Превоз на повредени автомобили, ванове, бусове</li>
                <li>Превоз на катастрофирали автомобили, ванове, бусове</li>
                <li>Превоз на Ретро автомобили</li>
                <li>Превоз на Рали и Офроуд автомобили</li>
                <li>
                    Превоз на Багери, Вишки, Мотокари, Валяци и всякакъв вид
                    техника до 3,5 тона
                </li>
                <li>Смяна на гуми на място</li>
                <li>Доставка на гориво</li>
                <li>Насочване към специализиран сервиз</li>
                <li>Транспортни услуги в София, Страната и Чужбина</li>
            </ul>
            <p>
                При нас ще получите коректно отношение и ниски цени,
                професионализъм и дългогодишен опит както у нас, така и в
                чужбина. Гарантираме високо качество на работа и бързина.
            </p>
        </div>
    </div>
</template>
