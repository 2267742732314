<template>
    <Header />
    <router-view class="main" />
    <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
    components: {
        Header,
        Footer,
    },
};
</script>

<style>
@font-face {
    font-family: Roboto;
    src: url(./assets/Roboto-Regular.ttf);
}
*,
*:after,
*:before {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    list-style-type: none;
}
.wrapper {
    width: 100%;
    height: 100%;
    max-width: 1240px;
    margin: 0 auto;
}
.main {
    min-height: calc(100vh - 120px);
}
.section {
    width: 100%;
    padding: 40px 20px;
    background-color: #fff9fb;
}
.section:nth-child(1) {
    height: calc(100vh - 60px);
    padding: 0;
    background-image: url("./assets/background-image.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.section:nth-child(1) .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.background-image-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #ffffff;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    margin: 0 20px;
}
.background-image-box > h1,
.background-image-box > h2 {
    text-align: center;
    margin-bottom: 20px;
}
.section:nth-child(2) {
    background-color: #e9e9e9;
}
.section:nth-child(2) > .wrapper > p {
    margin-bottom: 20px;
}
.section:nth-child(2) > .wrapper > p:last-child {
    margin-bottom: 0px;
}
.section:nth-child(2) > .wrapper > ul {
    padding: 0 0 0 40px;
    margin-bottom: 40px;
}
.section:nth-child(2) > .wrapper > ul > li {
    list-style-type: disc;
    margin-bottom: 10px;
}
.section:nth-child(3) > .wrapper > p {
    margin-bottom: 20px;
}
.section:nth-child(3) > .wrapper > p:last-child {
    margin-bottom: 0px;
}
.section:nth-child(3) > .wrapper > ul {
    padding: 0 0 0 40px;
    margin-bottom: 40px;
}
.section:nth-child(3) > .wrapper > ul > li {
    list-style-type: disc;
    margin-bottom: 10px;
}
.section:nth-child(4) {
    background-color: #1d1d1d;
}
.section-title {
    margin-bottom: 40px;
}
.section:nth-child(4) > .wrapper > .section-title {
    color: white;
}
.section:nth-child(4) > .wrapper > p {
    color: white;
    margin-bottom: 40px;
}
.call-us {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #218838;
    padding: 8px 16px !important;
    border-radius: 20px;
    text-align: center;
}
.call-us img {
    width: 30px;
    height: auto;
    margin-right: 10px;
}
.images {
    display: flex;
    flex-wrap: wrap;
}
.image {
    display: block;
    width: calc(50% - 10px);
    margin-bottom: 20px;
}
.image:nth-child(odd) {
    margin-right: 20px;
}

@media screen and (max-width: 900px) {
    .background-image-box {
        font-size: 20px;
        padding: 20px;
    }
}

@media screen and (max-width: 800px) {
    .navigation > a {
        font-size: 16px;
        padding: 19px 10px;
    }
}

@media screen and (max-width: 667px) {
    .image {
        width: 100%;
    }
    .image:nth-child(odd) {
        margin-right: 0px;
    }
}

@media screen and (max-width: 600px) {
    .background-image-box {
        font-size: 16px;
        padding: 20px;
    }
    .navigation {
        justify-content: space-around;
    }
}
</style>
