<template>
    <div id="gallery" class="section">
        <div class="wrapper">
            <h2 class="section-title">Галерия</h2>
            <div class="images">
                <img class="image" src="../assets/1.webp" alt="image-1" />
                <img class="image" src="../assets/2.webp" alt="image-2" />
                <img class="image" src="../assets/3.webp" alt="image-3" />
                <img class="image" src="../assets/4.webp" alt="image-4" />
                <img class="image" src="../assets/5.webp" alt="image-5" />
                <img class="image" src="../assets/6.webp" alt="image-6" />
                <img class="image" src="../assets/7.webp" alt="image-7" />
                <img class="image" src="../assets/8.webp" alt="image-8" />
            </div>
        </div>
    </div>
</template>
