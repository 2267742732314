<template>
    <div id="prices" class="section">
        <div class="wrapper">
            <h2 class="section-title">Цени</h2>
            <p>
                Цените важат за София и са без включено ДДС. Цените извън София
                се договарят.
            </p>
            <ul>
                <li>Мотоциклет - 50 лв</li>
                <li>Автомобили до 1000 кг. - 50 лв</li>
                <li>Автомобили над 1000 кг. - 60 лв</li>
                <li>Ван, Джип - 80 лв</li>
                <li>Бусове - 90 лв</li>
                <li>Строителна техника - 70 - 90 лв</li>
                <li>Нощна тарифа (21.00 - 7.00) - Посочената цена +50%</li>
                <li>Празници - +50%</li>
                <li>Липсваща, повредена или блокирала гума - +20 лв</li>
            </ul>
        </div>
    </div>
</template>
