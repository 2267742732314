<template>
    <div class="footer">
        <div class="wrapper">
            <div class="copyright">
                <p>Copyright © {{ currentYear }}. Всички права запазени</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style>
.footer {
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: black;
    min-height: 60px;
}
.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
