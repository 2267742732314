<template>
    <div class="header">
        <div class="wrapper">
            <div class="navigation">
                <router-link to="/">Начало</router-link>
                <router-link to="/services">Услуги</router-link>
                <router-link to="/prices">Цени</router-link>
                <router-link to="/about">За нас</router-link>
                <router-link to="/gallery">Галерия</router-link>
            </div>
        </div>
    </div>
</template>

<style>
.header {
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: black;
    min-height: 60px;
}
.navigation {
    display: flex;
    align-items: center;
}
.navigation > a {
    cursor: pointer;
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    padding: 19px;
}
</style>
