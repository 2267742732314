<template>
    <div class="section">
        <div class="wrapper">
            <div class="background-image-box">
                <h1>Денонощна пътна помощ</h1>
                <h2>Фирма Ел Зет 66 Ауто</h2>
                <a class="call-us" href="tel:0883567896"
                    ><img src="../assets/call.png" alt="Call us" /> 0883 56 78
                    96</a
                >
            </div>
        </div>
    </div>
</template>
